import { Controller } from '@hotwired/stimulus'
import { importEncryptionKey } from '@samedi/crypto-js/crypto'

import { fetchPatientPrivateKeyOnce } from '../encryption/encryption'
import { encryptInstitutionKeyData } from '../encryption/encryptionKeyHandling'
import { decryptFileMetadata } from '../services/downloadFiles'

export default class extends Controller {
  static targets = ['institutionOptions', 'institutionsForm', 'shareButton', 'fileName']
  static values = {
    fileId: String,
    encryptionKeyData: String,
    encryptionKeyId: String,
    encryptionKeyKeyPairId: String,
    encryptedName: String,
    decryptionErrorMessage: String,
  }

  async connect() {
    const labels = this.institutionOptionsTarget.getElementsByTagName('label')

    await this.showFileName()

    const patientPrivateKey = await fetchPatientPrivateKeyOnce(this.encryptionKeyKeyPairIdValue)
    const encryptionKey = await importEncryptionKey(
      patientPrivateKey,
      this.encryptionKeyDataValue,
      ['encrypt', 'decrypt'],
    )

    this.institutionsFormTarget.querySelector<HTMLInputElement>(
      "input[name='encryption_key_id']",
    )!.value = this.encryptionKeyIdValue

    for (const label of labels) {
      const inputData = label.querySelector('input')!
      const institutionKey = JSON.parse(label.dataset.institutionKey!)

      inputData.value = await encryptInstitutionKeyData(encryptionKey, institutionKey)
    }
  }

  enableShareButton() {
    this.shareButtonTarget.disabled = false
  }

  deselectInstitution(target: HTMLElement) {
    target.classList.remove('selected-institution', 'bg-primary-100')

    const checkIcon = target.querySelector('.check-icon')!
    checkIcon.classList.remove('fas', 'fa-circle-check', 'text-primary-900')
    checkIcon.classList.add('far', 'fa-circle', 'text-info-500')

    if (this.selectedInstitutions().length === 0) {
      this.disableShareButton()
    }
  }

  disableShareButton() {
    this.shareButtonTarget.disabled = true
  }

  save() {
    this.institutionsFormTarget.requestSubmit()
  }

  selectInstitution(target: HTMLElement) {
    target.classList.add('selected-institution', 'bg-primary-100')

    const checkIcon = target.querySelector('.check-icon')!
    const input = target.querySelector('input')!
    checkIcon.classList.remove('far', 'fa-circle', 'text-info-500')
    checkIcon.classList.add('fas', 'fa-circle-check', 'text-primary-900')
    input.checked = true

    this.enableShareButton()
  }

  selectedInstitutions(): HTMLCollectionOf<HTMLElement> {
    return this.institutionOptionsTarget.getElementsByClassName(
      'selected-institution',
    ) as HTMLCollectionOf<HTMLElement>
  }

  toggleInstitution(event: PointerEvent) {
    event.preventDefault()
    const target = event.currentTarget as HTMLElement

    if (target.classList.contains('selected-institution')) {
      this.deselectInstitution(target)
    } else {
      this.selectInstitution(target)
    }
  }

  async showFileName() {
    try {
      const fileName = await this.decryptFileName()
      this.setFileName(fileName || '')
    } catch (e) {
      this.setFileName(this.decryptionErrorMessageValue)
      throw e
    }
  }

  async decryptFileName() {
    const data = await decryptFileMetadata(
      {
        patientKeyPairId: this.encryptionKeyKeyPairIdValue,
        encryptedData: this.encryptionKeyDataValue,
      },
      '',
      this.encryptedNameValue,
    )

    if (!data) {
      return
    }

    return data.fileName
  }

  setFileName(fileName: string) {
    this.fileNameTarget.textContent = fileName
  }

  declare fileIdValue: string
  declare encryptionKeyDataValue: string
  declare encryptionKeyIdValue: string
  declare encryptionKeyKeyPairIdValue: string
  declare encryptedNameValue: string
  declare decryptionErrorMessageValue: string

  declare institutionOptionsTarget: HTMLElement
  declare institutionsFormTarget: HTMLFormElement
  declare shareButtonTarget: HTMLButtonElement
  declare fileNameTarget: HTMLElement
}
