import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="back"
export default class extends Controller {
  disconnect() {
    this.element.removeEventListener('click', this.back)
  }

  connect() {
    this.element.addEventListener('click', this.back)
  }

  back = (event: Event) => {
    try {
      history.back()
      event.preventDefault()
    } catch (_error) {
      // Do nothing. It will fall back to the referrer in href
    }
  }
}
